body {
    overflow-x: hidden;;
}

.logo {
    font-size: 26px;
    text-decoration: none;
    font-weight: 600;
}

.logo, .logo:hover {
    color: rgb(191 190 203);
}

.header-bg {
    position: absolute;
    top: 0;
    z-index: 3;
}

.header-inner {
    width: 80%;
    margin: auto;
}

.header-content {
    position: relative;
    height: 100%;
}

.intro-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 100px;
    pointer-events: none;
    text-transform: uppercase;
    font-weight: 800;
    z-index: -1;
    color: #323741;
    white-space: nowrap; /* Chrome */
}

.user-img {
    border: 6px solid #61dafb;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    overflow: hidden;
}

.user-info h1 {
    font-size: 46px;
    line-height: 1.2;
    font-weight: 800;
}

.text-intro {
    margin-right: 20px;
}

.intro-desc {
    line-height: 1.5;
}

.user-info .Typewriter__wrapper, .user-info .Typewriter__cursor {
    color: #61dafb;
}

.header-btn {
    background: none;
    color: rgb(191 190 203);
    border: 1px solid rgb(191 190 203);
    padding: 5px 10px;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    transition: all .3s;
}

.header-btn:hover {
    color: #fff;
    background: #61dafb;
}

.header-btn:hover:before {
    margin-left: 0;
}

.header-btn::before {
    margin-left: -100%;
    box-shadow: inset 0 0 0 60px #61dafb;
}

.list-btn {
    margin-right: 10px;
}

.intro-desc {
    max-width: 650px;
    margin: auto;
    display: block;
}

@media (max-width: 733px) {
    .intro-desc {
        padding: 0 30px;
    }

    .user-info h1 {
        font-size: 36px;
    }
}

@media (max-width: 500px) {
    .header-intro {
        flex-wrap: wrap
    }

    .text-intro {
        width: 100%;
    }
}