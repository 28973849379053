.resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid #61dafb;
    position: relative;
}

.resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #61dafb;
}

.resume h5 {
    font-size: 16px;
    background: #e4edf9;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}
