@import '@fontsource/roboto';
@import '@fontsource/roboto/900.css';

body {
  background-color: #282c34;
  color: rgb(191 190 203);
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

.App,
#root {
  height: 100%;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.border-r {
  border-right: 1px solid #989898;
}

.opacity-05 {
  opacity: 0.1;
}

.button,
.text-section {
  position: relative;
  z-index: 3;
}

.lines-bg {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: -1;
}

.full-width {
  width: 100%;
}

.inner-page {
  padding-top: 200px;
}

img {
  max-width: 100%;
}

.section {
  margin-bottom: 100px;
}

.z-index {
  z-index: 2;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #282c34;
}
textarea {
  resize: none;
}

.btn {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}
